import http from '@/http-common'

const getAll = async modelType => {
  return await http
    .get('/document-model', {
      params: {
        type: modelType,
      },
    })
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const create = async (file, { type, name }) => {
  var formData = new FormData()
  formData.append('file', file)
  formData.append('name', name)
  return http
    .post(`/document-model/${type}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const setDefault = async modelId => {
  return http
    .put(`/document-model/${modelId}/default`)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}
const setActive = async (modelId, active = true) => {
  return http
    .put(`/document-model/${modelId}/${active ? 'active' : 'inactive'}`)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const upload = async (type, data) => {
  var formData = new FormData()
  formData.append('file', data.file)
  formData.append('name', data.name)
  return http
    .post(`/document-model/${type}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const destroy = async modelId => {
  return http
    .delete(`/document-model/${modelId}`)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

export default {
  getAll,
  create,
  setDefault,
  setActive,
  upload,
  delete: destroy
}
