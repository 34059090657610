import http from '@/http-common'

const safeGetAll = async () => {
  return await http
    .get('/docsign/safe')
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}
const safeSetDefault = async (uuid_safe) => {
  return await http
    .put('/docsign/safe/default', {uuid_safe: uuid_safe})
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const contractSign = async ( data) => {
  return await http
    .post('/docsign/contract', data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

export default {
  contract: {
    sign: contractSign
  },
  safe: {
    getAll: safeGetAll,
    setDefault: safeSetDefault  
  }
}
